import { Query } from 'react-apollo';
import isAuthenticatedQuery from 'graphql/queries/isAuthenticated.graphql';

export default ({ children }) => (
  <Query query={isAuthenticatedQuery}>
    {({ loading, error, data }) => {
      if (loading || error) {
        return null;
      }

      return children(data.isAuthenticated);
    }}
  </Query>
);
